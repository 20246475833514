import { inject, InjectionToken } from '@angular/core';
import { RlePortalLibConfigService } from '@rle-portal/lib';

import { ApiFirebaseClient } from '../../api-client/api-firebase-client.service';
import { UserFirebaseRepository } from './user-firebase-repository';
import { UserMockRepository } from './user-mock-repository';

export const USER_REPOSITORY = new InjectionToken<UserRepository>('UserRepository', {
	providedIn: 'root',
	factory: () => {
		const config = inject(RlePortalLibConfigService).getConfig();
		return config.useMockData ? new UserMockRepository() : new UserFirebaseRepository(inject(ApiFirebaseClient));
	}
});

export interface UserRepository {
	setTokenClaims(userId: string, accessToken: string): Promise<void>;
}
