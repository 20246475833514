// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from './environment.interface';
import { Constants } from './version';

export const environment: Environment = {
	version: Constants.version,
	production: false,
	nativeAppLinkHost: 'dev.customers.portal.labs.rle.de',
	firebase: {
		apiKey: 'AIzaSyA2qgF50lD0iVXzKvfEZa14laKXJeo_WkI',
		authDomain: 'rle-portal-customers-dev.firebaseapp.com',
		projectId: 'rle-portal-customers-dev',
		storageBucket: 'rle-portal-customers-dev.appspot.com',
		messagingSenderId: '725555091119',
		appId: '1:725555091119:web:0e810377b1ed23d791e65a'
	},
	keycloak: {
		url: 'https://identity.dev.portal.labs.rle.de',
		realm: 'master',
		clientId: 'rleportal-customers',
		clientSecret: '?',
		redirectUri: 'https://dev.customers.portal.labs.rle.de'
	},
	featureToggles: {},
	// Enable client side logging
	clientLogEnabled: true,
	// Use local emulator for authentication (must be started before)
	useAuthEmulator: false,
	// Use local emulator for database (must be started before)
	useDBEmulator: false,
	// Use local emulator for storage (must be started before)
	useStorageEmulator: false,
	// Use local emulator for functions (must be started before)
	useFunctionsEmulator: false,
	// Use mock data instead of accessing the real database
	useMockData: false,
	// Override system settings to force the theme mode to 'light' or 'dark'
	forceThemeMode: null,
	// Add an extra top padding for the device status bar / notch
	forceStatusbarPadding: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
