import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../auth.service';

export const authGuard: CanActivateFn = (route, state) => {
	const authService = inject(AuthService);
	const router = inject(Router);

	console.log('Check login');
	// not logged in => login
	if (!authService.isLoggedIn()) {
		console.log('Not logedin');
		authService.login(origin);
		return router.parseUrl('/login');
	}

	// logged in => access
	return true;

	// public canActivate(
	// 	next: ActivatedRouteSnapshot,
	// 	state: RouterStateSnapshot
	// ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
	// 	return this.checkLogin(state.url);
	// }

	// public canActivateChild(
	// 	next: ActivatedRouteSnapshot,
	// 	state: RouterStateSnapshot
	// ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
	// 	return this.canActivate(next, state);
	// }

	// public canMatch(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
	// 	return this.checkLogin(segments.join('/'));
	// }
};
