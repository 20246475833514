<div
	cdkVirtualScrollingElement
	class="virtual-scrolling-wrapper"
	[class.disabled]="!isScrollingEnabled"
	[class.ion-content-scroll-host]="isScrollHost"
>
	<div class="virtual-scrolling-header">
		<ng-content select="header"></ng-content>
		<ng-container
			*ngIf="
				loadedItems !== null && loadedItems !== undefined;
				else loading
			"
		>
			<ng-template
				*ngIf="loadedItems.length === 0"
				[ngTemplateOutlet]="noDataTemplate || defaultNoDataTpl"
			>
			</ng-template>
		</ng-container>
	</div>

	<cdk-virtual-scroll-viewport
		[itemSize]="itemHeight"
		[minBufferPx]="minBufferPx"
		[maxBufferPx]="maxBufferPx"
		orientation="vertical"
	>
		<ion-list
			inset="true"
			class="main-list"
			[ngStyle]="styleCSS"
			[hidden]="!loadedItems?.length"
		>
			<ng-container
				*cdkVirtualFor="
					let item of loadedItems;
					let index = index;
					let count = count;
					let first = first;
					let last = last
				"
			>
				<div *ngIf="item?.isFirstItemInGroup">
					<ng-template
						[ngTemplateOutlet]="
							groupHeaderTemplate || defaultItemDividerHeaderTpl
						"
						[ngTemplateOutletContext]="{
							$implicit: item,
							index,
							count,
							first,
							last
						}"
					>
					</ng-template>
				</div>

				<div id="virtual_scroll_el_{{ index }}">
					<ng-template
						[ngTemplateOutlet]="
							itemContentTemplate || defaultItemContentTpl
						"
						[ngTemplateOutletContext]="{
							$implicit: item,
							index,
							count,
							first,
							last
						}"
					>
					</ng-template>
				</div>
			</ng-container>
		</ion-list>
	</cdk-virtual-scroll-viewport>

	<div class="virtual-scrolling-footer">
		<ng-content select="footer"></ng-content>
	</div>
</div>

<div #alphaList [hidden]="!isAlphabetSideBarVisible" class="alpha-sidebar">
	<div
		*ngFor="let letter of alphabet; let index = index"
		id="virtual_scroll_letter_{{ index }}"
		(click)="goToLetter(letter)"
		class="letter"
	>
		{{ letter }}
	</div>
</div>

<ion-fab
	*ngIf="isScrollToTopVisible"
	vertical="bottom"
	horizontal="end"
	class="scroll-top"
>
	<ion-fab-button (click)="scrollToTop()" size="small" color="tertiary">
		<ion-icon name="chevron-up-outline"></ion-icon>
	</ion-fab-button>
</ion-fab>

<ng-template #defaultItemDividerHeaderTpl let-item>
	<ion-item-divider mode="md">
		<ng-container *ngIf="item.groupHeader !== ''; else emptyHeader">{{
			item.groupHeader
		}}</ng-container>
		<ng-template #emptyHeader>
			<i translate>generic.notSpecified</i>
		</ng-template>
	</ion-item-divider>
</ng-template>

<ng-template #defaultItemContentTpl let-item>
	<ion-item [detail]="false">
		<ion-label className="ion-text-wrap">
			{{ item }}
		</ion-label>
	</ion-item>
</ng-template>

<ng-template #defaultLoadingTpl>
	<ion-list inset="true">
		<ion-item *ngFor="let i of [1, 2, 3]" [detail]="false">
			<ion-label className="ion-text-wrap">
				<ion-skeleton-text
					animated
					style="width: 100px; display: inline-block"
				></ion-skeleton-text>
			</ion-label>
		</ion-item>
	</ion-list>
</ng-template>

<ng-template #defaultNoDataTpl>
	<ion-list inset="true">
		<ion-item [detail]="false">
			<ion-label className="ion-text-wrap">{{
				noDataKey ?? 'generic.noData' | translate
			}}</ion-label>
		</ion-item>
	</ion-list>
</ng-template>

<ng-template #loading>
	<ng-template [ngTemplateOutlet]="loadingTemplate || defaultLoadingTpl">
	</ng-template>
</ng-template>
