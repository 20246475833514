export class UrlHelper {
	public static URL_PARAM_MATCHER = new RegExp(/([^/]*)\/*$/);

	public static getLastElement(url: string): string | undefined {
		const matchedUrlArray = this.URL_PARAM_MATCHER.exec(url);
		if (matchedUrlArray?.length) {
			return matchedUrlArray[1];
		} else {
			return undefined;
		}
	}
}
