import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { RlePortalLibConfig } from '../rle-portal-lib-config.interface';
import { LIB_CONFIG } from '../rle-portal-lib-config.token';

export type ThemeMode = 'light' | 'dark';

@Injectable({
	providedIn: 'root'
})
export class ThemeService {
	public themeMode: Observable<ThemeMode>;
	private themeModeSubject = new ReplaySubject<ThemeMode>(1);

	constructor(
		@Inject(LIB_CONFIG) private config: RlePortalLibConfig,
		@Inject(DOCUMENT) private doc: Document
	) {
		this.themeMode = this.themeModeSubject.asObservable();

		// Check for development flag which forces the dark mode to en-/disabled
		if (this.config.forceThemeMode) {
			this.themeModeSubject.next(this.config.forceThemeMode === 'dark' ? 'dark' : 'light');
			return;
		}

		const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
		this.themeModeSubject.next(prefersDark.matches ? 'dark' : 'light');

		// Listen for changes to the selected mode
		prefersDark.onchange = mediaQuery => this.themeModeSubject.next(mediaQuery.matches ? 'dark' : 'light');
	}

	public switchThemeMode(toDark: boolean): void {
		document.body.classList.toggle('dark', toDark);
	}
}
