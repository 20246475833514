import { Injectable } from '@angular/core';
import {
    collection,
    collectionData,
    collectionGroup,
    CollectionReference,
    deleteDoc,
    doc,
    docData,
    DocumentData,
    DocumentReference,
    Firestore,
    FirestoreDataConverter,
    limit,
    orderBy,
    query,
    QueryConstraint,
    QueryDocumentSnapshot,
    runTransaction,
    serverTimestamp,
    setDoc,
    SnapshotOptions,
    Transaction,
    UpdateData,
    updateDoc,
    where,
    WithFieldValue,
    writeBatch,
} from '@angular/fire/firestore';
import { ArrayUtils } from '@rle-portal/lib';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiClientModule } from './api-client.module';

export type OrderByFieldDirection = 'asc' | 'desc';
export type OrderByField = { name: string; direction: OrderByFieldDirection };
@Injectable({
	providedIn: ApiClientModule
})
export class ApiFirestoreClient {
	private defaultConverter: FirestoreDataConverter<any>;

	constructor(private store: Firestore) {
		this.defaultConverter = {
			toFirestore: (modelObject: WithFieldValue<any>) => {
				return { ...modelObject };
			},
			fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>, options?: SnapshotOptions) => {
				const data = snapshot.data(options);
				return {
					...data,
					id: snapshot.id
				};
			}
		};
	}

	public collection<T>(collectionName: string, queryConstraints: QueryConstraint[] = []): Observable<T[]> {
		return collectionData<T>(query<T, DocumentData>(this.collectionRef<T>(collectionName), ...queryConstraints));
	}

	public queryWithListFilter<T>(list: string[], queryFn: (list: string[]) => Observable<T[]>): Observable<T[]> {
		if (list.length >= 10) {
			const chunkedList: string[][] = ArrayUtils.chunks<string>(list, 10);
			const queries: Observable<T[]>[] = [];
			chunkedList.forEach(chunk => queries.push(queryFn(chunk)));

			return combineLatest<T[][]>(queries).pipe(map(items => ([] as T[]).concat(...items)));
		} else {
			return queryFn(list);
		}
	}

	public collectionRef<T>(collectionName: string): CollectionReference<T> {
		return collection(this.store, collectionName).withConverter<T>(this.defaultConverter);
	}

	public item<T>(collectionName: string, id: string): Observable<T | null> {
		return docData<T>(doc(this.store, `${collectionName}/${id}`).withConverter<T>(this.defaultConverter)).pipe(
			map(i => i ?? null)
		);
	}

	public itemRef<T>(collectionName: string, id: string): DocumentReference<T> {
		return doc(this.store, `${collectionName}/${id}`).withConverter<T>(this.defaultConverter);
	}

	public subCollection<T>(
		collectionName: string,
		id: string,
		subCollectionName: string,
		queryConstraints: QueryConstraint[] = []
	): Observable<T[]> {
		return collectionData<T>(
			query<T, DocumentData>(this.subCollectionRef(collectionName, id, subCollectionName), ...queryConstraints)
		);
	}

	public subCollectionRef<T>(collectionName: string, id: string, subCollectionName: string): CollectionReference<T> {
		return collection(this.itemRef<T>(collectionName, id), subCollectionName).withConverter<T>(
			this.defaultConverter
		);
	}

	public add<T>(collectionName: string, data: T): Promise<string> {
		let id: string | number | null = null;
		if (Object.prototype.hasOwnProperty.call(data, 'id')) {
			id = Object.getOwnPropertyDescriptor(data, 'id')?.value;
		}

		if (id || id === 0) {
			// Ensure compatibility with Firebase plain object documents
			data = {
				...data,
				...{ id }
			};
		} else {
			data = this.addId(data);
			id = Object.getOwnPropertyDescriptor(data, 'id')?.value;
		}

		data = this.addServerTimeStampToCreatedDateIfExist<T>(data);
		data = this.setServerTimeStampToModifiedDateIfExist<T>(data);

		try {
			return setDoc<T, DocumentData>(this.itemRef<T>(collectionName, id!.toString()), data).then(() =>
				Promise.resolve(id!.toString())
			);
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public batchAdd<T>(collectionName: string, dataList: T[]): Promise<T[]> {
		const storedDataList: T[] = [];
		const dataListArray: T[][] = dataList.reduce<T[][]>((all, one, i) => {
			const ch = Math.floor(i / 500);
			all[ch] = ([] as T[]).concat(all[ch] || [], one);
			return all;
		}, []);

		const batches = dataListArray.map(dataL => {
			const batch = writeBatch(this.store);

			dataL.forEach((data: T) => {
				let id: string | number | null = null;
				if (Object.prototype.hasOwnProperty.call(data, 'id')) {
					id = Object.getOwnPropertyDescriptor(data, 'id')?.value;
				}
				if (!id && id !== 0) {
					data = this.addId(data);
					id = Object.getOwnPropertyDescriptor(data, 'id')?.value;
				} else {
					// Ensure compatibility with Firebase plain object documents
					data = { ...data, ...{ id } };
				}

				data = this.addServerTimeStampToCreatedDateIfExist<T>(data);
				data = this.setServerTimeStampToModifiedDateIfExist<T>(data);

				batch.set(this.itemRef<T>(collectionName, id!.toString()), data);

				storedDataList.push(data);
			});

			return batch;
		});

		try {
			return Promise.all(batches.map(b => b.commit())).then(() => Promise.resolve(storedDataList));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public batchDelete<T>(collectionName: string, dataList: T[]): Promise<void> {
		const dataListArray: T[][] = dataList.reduce<T[][]>((all, one, i) => {
			const ch = Math.floor(i / 500);
			all[ch] = ([] as T[]).concat(all[ch] || [], one);
			return all;
		}, []);

		const batches = dataListArray.map(dataL => {
			const batch = writeBatch(this.store);

			dataL.forEach(data => {
				const id: string = Object.getOwnPropertyDescriptor(data, 'id')?.value.toString();

				batch.delete(this.itemRef<T>(collectionName, id));
			});

			return batch;
		});

		return Promise.all(batches.map(b => b.commit())).then(() => Promise.resolve());
	}

	public addId<T>(data: T): T {
		const id = this.createId();
		return { ...data, ...{ id } };
	}

	public createId(): string {
		return doc(collection(this.store, '_')).id;
	}

	public update<T>(collectionName: string, id: string | null, item: UpdateData<T>): Promise<void> {
		if (!id) {
			return Promise.reject();
		}
		item = this.validateUpdateId<UpdateData<T>>(id, item);
		item = this.setServerTimeStampToModifiedDateIfExist<UpdateData<T>>(item);

		try {
			return updateDoc<T, DocumentData>(this.itemRef<T>(collectionName, id), item as any);
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public delete(collectionName: string, id: string | null): Promise<void> {
		if (!id) {
			return Promise.resolve();
		}
		try {
			return deleteDoc(this.itemRef(collectionName, id));
		} catch (e) {
			return Promise.reject(e);
		}
	}

	public softDelete(collectionName: string, id: string | null): Promise<void> {
		let updateData = { deleted: true, modifiedDate: null };
		return this.update(collectionName, id, updateData);
	}

	public addSubItem<T>(collectionName: string, id: string, subCollectionName: string, data: T): Promise<string> {
		return this.add<T>(`${collectionName}/${id}/${subCollectionName}`, data);
	}

	public subItem<T>(
		collectionName: string,
		id: string,
		subCollectionName: string,
		subId: string
	): Observable<T | null> {
		return this.item<T>(`${collectionName}/${id}/${subCollectionName}`, subId);
	}

	public updateSubItem<T>(
		collectionName: string,
		id: string,
		subCollectionName: string,
		subId: string,
		item: UpdateData<T>
	): Promise<void> {
		if (!id) {
			return Promise.reject();
		}
		return this.update<T>(`${collectionName}/${id}/${subCollectionName}`, subId, item);
	}

	public deleteSubItem(collectionName: string, id: string, subCollectionName: string, subId: string): Promise<void> {
		if (!id) {
			return Promise.resolve();
		}
		return this.delete(`${collectionName}/${id}/${subCollectionName}`, subId);
	}

	public runTransaction(updateFunction: (transaction: Transaction) => Promise<unknown>): Promise<unknown> {
		try {
			return runTransaction(this.store, updateFunction);
		} catch (e) {
			return Promise.reject(e);
		}
	}

	/* collectionGroup */
	public collectionGroup<T>(collectionName: string, queryConstraints: QueryConstraint[] = []): Observable<T[]> {
		return collectionData<T>(
			query<T, DocumentData>(
				collectionGroup(this.store, collectionName).withConverter<T>(this.defaultConverter),
				...queryConstraints
			)
		);
	}

	public collectionGroupItem<T>(
		collectionName: string,
		id?: string | number,
		queryConstraints: QueryConstraint[] = []
	): Observable<T | null> {
		return this.collectionGroup<T>(
			collectionName,
			id || id === 0 ? [where('id', '==', id), limit(1)] : queryConstraints
		).pipe(switchMap(l => of(l.length ? l[0] : null)));
	}

	public buildOrderBy(orderByFields?: OrderByField[]): QueryConstraint[] {
		return orderByFields?.map(field => orderBy(field.name, field.direction)) ?? [];
	}

	private validateUpdateId<T>(id: string, item: T): T {
		let validatedId: string | number = id;
		if (Object.prototype.hasOwnProperty.call(item, 'id')) {
			validatedId = Object.getOwnPropertyDescriptor(item, 'id')?.value;
			validatedId = validatedId?.toString() === id ? validatedId : id;
		}
		// Ensure compatibility with Firebase plain object documents
		return { ...item, ...{ id: validatedId } };
	}

	// if createdDate exist, then add server timestamp
	private addServerTimeStampToCreatedDateIfExist<T>(data: T): T {
		if (Object.prototype.hasOwnProperty.call(data, 'createdDate') && !(data as any).createdDate)
			return {
				...data,
				createdDate: serverTimestamp()
			};
		else {
			return data;
		}
	}

	// if modifiedDate exist, then add server timestamp
	private setServerTimeStampToModifiedDateIfExist<T>(data: T): T {
		if (Object.prototype.hasOwnProperty.call(data, 'modifiedDate'))
			return {
				...data,
				modifiedDate: serverTimestamp()
			};
		else {
			return data;
		}
	}
}
