<ion-header translucent="false" class="ion-no-border">
	<ion-toolbar>
		<ion-title translate>public.errorPage.title</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen="true">
	<ion-card class="ion-text-center">
		<ion-card-content>
			<h1 translate>public.errorPage.title</h1>
			<p translate>public.errorPage.message</p>

			<ion-button [routerLink]="['/']" [replaceUrl]="true" translate>
				<ion-icon slot="start" name="home"></ion-icon>
				generic.backToHome
			</ion-button>
		</ion-card-content>
	</ion-card>

	<div class="footer-spacer"></div>
</ion-content>
