import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanMatch,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../auth.service';

@Injectable({
	providedIn: 'root'
})
export class HideLoginPageGuard implements CanActivate, CanActivateChild, CanMatch {
	constructor(
		private authService: AuthService,
		private router: Router
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.checkLoggedin();
	}

	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(next, state);
	}

	canMatch(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
		return this.checkLoggedin();
	}

	private checkLoggedin(): boolean | Promise<boolean> {
		// logged in => redirect to /
		if (this.authService.isLoggedIn()) {
			this.router.navigateByUrl('/', {
				replaceUrl: true
			});
			return false;
		}

		return true;
	}
}
