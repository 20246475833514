import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { FieldValue, Timestamp } from '@angular/fire/firestore';
import { I18nService } from '@rle-portal/lib';
import moment, { Moment } from 'moment';

import { TimestampUtils } from '../utils/timestamp.utils';

@Pipe({
	standalone: true,
	name: 'firebaseDate'
})
export class FirebaseDatePipe implements PipeTransform {
	constructor(
		@Inject(LOCALE_ID) private systemLocale: string,
		private readonly i18nService: I18nService
	) {}

	public transform(
		value: Timestamp | FieldValue | null,
		format?: string,
		timezone?: string,
		locale?: string,
		...args: any[]
	): string | null {
		if (value) {
			let momentValue: Moment | null = moment.utc();
			if (Object.prototype.hasOwnProperty.call(value, 'seconds')) {
				momentValue = TimestampUtils.toMoment(value as Timestamp);
			}

			// Check for format keywords. Allowed values are: date, dateLong, dateTime, dateTimeLong, time
			if (format) {
				format = this.i18nService.getInstant(`generic.dateFormat_${format}`).replace('generic.dateFormat_', '');
			}

			const pipe = new DatePipe(this.systemLocale);
			return pipe.transform(
				// Use UTC ISO, since the timezone is handled in DatePipe
				momentValue?.toISOString(),
				format,
				timezone,
				locale
			);
		}

		return '';
	}
}
