import { Component } from '@angular/core';

@Component({
	selector: 'rleportal-error-page',
	templateUrl: './error.page.html',
	styleUrls: ['./error.page.scss']
})
export class ErrorPage {
	constructor() {}
}
