import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '@rle-environments/environment';
import { RlePortalLibModule } from '@rle-portal/lib';
import { ApiClientModule, AuthModule, AuthService } from '@rle-portal/lib/firebase';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeDe, 'de');
class DynamicLocaleId extends String {
	constructor(protected service: TranslateService) {
		super('');
	}

	public override toString(): string {
		return this.service.currentLang;
	}
}

const appInitializerFn = (authService: AuthService) => () => {
	return authService.init();
};

const runtimeApplicationConfiguration = {
	provide: APP_INITIALIZER,
	useFactory: appInitializerFn,
	multi: true,
	deps: [AuthService]
};

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => {
	return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${environment.version as string}`);
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			backButtonText: '',
			backButtonIcon: '/assets/icons/back.svg',
			backButtonDefaultHref: '/',
			_forceStatusbarPadding: environment.forceStatusbarPadding
		}),
		RlePortalLibModule.forRoot(environment),
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		NgxPermissionsModule.forRoot(),
		ReactiveFormsModule,
		ApiClientModule,
		AuthModule,
		AppRoutingModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		})
	],
	providers: [
		runtimeApplicationConfiguration,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: LOCALE_ID,
			deps: [TranslateService],
			useClass: DynamicLocaleId
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
