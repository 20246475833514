export class SortHelper {
	public static sort<T>(data: T[], propertyValue: (item: T) => string | number | boolean, DESC?: boolean): T[] {
		return data.sort((a, b) => {
			let aValue = propertyValue(a);
			let bValue = propertyValue(b);

			if (typeof aValue === 'string' && typeof bValue === 'string') {
				aValue = SortHelper.normalizeString(aValue);
				bValue = SortHelper.normalizeString(bValue);
			}

			if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
				if (DESC) {
					return aValue === bValue ? 0 : aValue ? -1 : 1;
				} else {
					return aValue === bValue ? 0 : aValue ? 1 : -1;
				}
			} else {
				if (DESC) {
					return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
				} else {
					return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
				}
			}
		});
	}

	public static sortNumbersToEndAsc<T>(data: T[], propertyValue: (item: T) => string): T[] {
		return data.sort((a, b) =>
			propertyValue(a) === propertyValue(b) ||
			(!SortHelper.isNumeric(propertyValue(a)) && !SortHelper.isNumeric(propertyValue(b)))
				? 0
				: SortHelper.isNumeric(propertyValue(a)) && SortHelper.isNumeric(propertyValue(b))
					? +propertyValue(a) < +propertyValue(b)
						? -1
						: 1
					: SortHelper.isNumeric(propertyValue(a))
						? 1
						: -1
		);
	}

	public static isNumeric(value?: string | null): boolean {
		return value !== null && value !== undefined && /^-?\d+$/.test(value);
	}

	public static isStartingWithLetter(value?: string | null): boolean {
		return value !== null && value !== undefined && /^[A-Za-z].*$/.test(value);
	}

	public static firstLetterGrouping(value: string): string {
		return SortHelper.isStartingWithLetter(value) ? value[0].toUpperCase() : '#';
	}

	public static queryFilterFunc(query: string, oneCharCompareFirstFieldOnly = true): (strs: string[]) => boolean {
		if (query?.trim()) {
			const queryParts = query?.trim().replace(/ {2,}/, ' ').toLowerCase().split(' ');

			return strs =>
				queryParts.every(q =>
					(oneCharCompareFirstFieldOnly && q.length === 1 && strs.length ? [strs[0]] : strs).some(
						str => (str?.toLowerCase().indexOf(q) === (q.length === 1 ? 0 : -1)) === (q.length === 1)
					)
				);
		}

		return () => true;
	}

	public static normalizeString(str: string): string {
		str = str?.toLowerCase()?.normalize() ?? '';

		str = str.replace(/ä/g, 'a');
		str = str.replace(/ö/g, 'o');
		str = str.replace(/ü/g, 'u');
		str = str.replace(/ß/g, 's');

		return str;
	}
}
