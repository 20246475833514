export class ArrayUtils {
	public static chunks<T>(list: T[], chunkSize: number): T[][] {
		return list.reduce((chunks: T[][], item: T, index) => {
			const chunk = Math.floor(index / chunkSize);
			chunks[chunk] = ([] as T[]).concat(chunks[chunk] || [], item);
			return chunks;
		}, []);
	}

	public static isEquals<T>(list1: T[], list2: T[], itemEqualsFunc?: (item1: T, item2: T) => boolean): boolean {
		return (
			(list1?.length ?? -1) === (list2?.length ?? -1) &&
			(list1?.every(item1 =>
				itemEqualsFunc ? list2?.some(item2 => itemEqualsFunc(item1, item2)) : list2?.includes(item1)
			) ??
				true)
		);
	}
}
