import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ErrorRoutingModule } from './error-routing.module';
import { ErrorPage } from './error.page';
import { NotFoundPage } from './not-found/not-found.page';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, ErrorRoutingModule, TranslateModule],
	declarations: [ErrorPage, NotFoundPage]
})
export class ErrorPageModule {}
