import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'rleportal-not-found-page',
	templateUrl: './not-found.page.html',
	styleUrls: ['./not-found.page.scss']
})
export class NotFoundPage implements OnInit {
	public titleKey?: string;
	public messageKey?: string;
	public link?: string;

	constructor(private router: Router) {}

	public ngOnInit(): void {
		this.titleKey = this.titleKey || this.router.getCurrentNavigation()?.extras?.state?.['titleKey'];
		this.messageKey = this.messageKey || this.router.getCurrentNavigation()?.extras?.state?.['messageKey'];
		this.link = this.link || this.router.getCurrentNavigation()?.extras?.state?.['link'];
	}
}
