import { Injectable } from '@angular/core';
import { delayedPromise, UserDefaultsService } from '@rle-portal/lib';

import { NotificationService } from '../notification.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlingService {
	private static readonly MAX_RETRIES: number = 2;
	private static readonly MIN_TIME_DIFF_TO_RESET_COUNTER: number = 60;

	constructor(
		private notificationService: NotificationService,
		private userDefaultsService: UserDefaultsService
	) {}

	public async handleFatalError(error: Error, messageTag?: string, skipReload = false): Promise<void> {
		console.error(error);

		if (!skipReload) {
			const lastErrorTimestamp = await this.userDefaultsService.getLastHandledErrorTimestamp();
			let retryCounter = await this.userDefaultsService.getHandledErrorRetriesCounter();

			const timestamp = Math.round(new Date().getTime() / 1000);
			if (timestamp - (lastErrorTimestamp || timestamp) > ErrorHandlingService.MIN_TIME_DIFF_TO_RESET_COUNTER) {
				retryCounter = 0;
				await this.userDefaultsService.setHandledErrorRetriesCounter(retryCounter);
			}
			await this.userDefaultsService.setLastHandledErrorTimestamp(timestamp);

			if (retryCounter < ErrorHandlingService.MAX_RETRIES) {
				retryCounter++;
				await this.userDefaultsService.setHandledErrorRetriesCounter(retryCounter);
				console.log(`Auto retry[${retryCounter}]...`);
				return this.notificationService.showSpinner().then(() =>
					delayedPromise(400)
						.then(() => this.notificationService.hideSpinner())
						.then(() => window.location.reload())
				);
			}
		}

		return this.notificationService.showFatalError(messageTag);
	}
}
