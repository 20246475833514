import { Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'rleportal-login-page',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss']
})
export class LoginPage {
	constructor() {}
}
