import { CdkVirtualScrollableElement, CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { GestureController, IonicModule, IonList } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { delayedPromise, instanceOfObservable, SortHelper } from '@rle-portal/lib';
import { firstValueFrom, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
	standalone: true,
	selector: 'app-virtual-scroll',
	templateUrl: './virtual-scroll.component.html',
	styleUrls: ['./virtual-scroll.component.scss'],
	imports: [IonicModule, CommonModule, ScrollingModule, TranslateModule]
})
export class VirtualScrollComponent implements OnChanges, AfterViewInit, OnDestroy {
	@ViewChild(CdkVirtualScrollableElement)
	public scrollElement?: CdkVirtualScrollableElement;
	@ViewChild(CdkVirtualScrollViewport)
	public viewport?: CdkVirtualScrollViewport;
	@ViewChild(IonList)
	public list?: IonList;
	@ViewChild('alphaList')
	public alphabetSidebarContent?: ElementRef;

	@Input()
	public items: Observable<any[]> | any[] | null = null;
	@Input()
	public itemHeight?: number;
	@Input()
	public minBufferPx = 3000;
	@Input()
	public maxBufferPx = 4000;
	@Input()
	public groupHeaderHeight = 31;
	@Input()
	public groupHeaderFunc?: (item: any) => string;
	@Input()
	public showAlphabetSideBar = false;
	@Input()
	public alphabetSideBarMinCount = 20;
	@Input()
	public itemContentTemplate?: TemplateRef<any>;
	@Input()
	public groupHeaderTemplate?: TemplateRef<any>;
	@Input()
	public loadingTemplate?: TemplateRef<any>;
	@Input()
	public noDataTemplate?: TemplateRef<any>;
	@Input()
	public noDataKey?: string;
	@Input()
	public isScrollHost = true;
	@Input()
	public scrollParent = false;
	@Input()
	public styleCSS = {};

	@Output()
	public parentScrollingEnabled = new EventEmitter<boolean>();

	public isParentScrollingEnabled = false;
	public isScrollingEnabled = true;

	public loadedItems: any[] | null = null;
	public alphabet: string[] = [];
	public isAlphabetSideBarVisible = false;
	public isScrollToTopVisible = false;

	private alphabetLetterIndex = new Map<string, number>();
	private alphabetSideBarTopOffset: number | null = null;
	private alphabetSidebarReadySubject = new ReplaySubject<boolean>(1);
	private alphabetSidebarReady$: Observable<boolean>;

	private itemsSubscription?: Subscription;
	private parentScrollSize: number | null = null;
	private lastScrollPos: number | null = null;
	private destruction = new Subject<void>();

	constructor(private gestureController: GestureController) {
		this.alphabetSidebarReady$ = this.alphabetSidebarReadySubject.asObservable();
	}

	public ngOnChanges(): void {
		this.initComponent();
	}

	public ngAfterViewInit(): void {
		if (!this.itemHeight) {
			console.error('VirtualScroll ERROR: No itemHeight specified!');
		}

		this.scrollElement
			?.elementScrolled()
			.pipe(takeUntil(this.destruction))
			.subscribe(e => this.onScrolling(e));
		this.enableParentScrolling(this.scrollParent);

		this.alphabetSidebarReadySubject.next(true);
	}

	public ngOnDestroy(): void {
		this.destruction.next();
		this.destruction.complete();
	}

	public async onParentScrolling(event: any): Promise<void> {
		if (!this.isParentScrollingEnabled) {
			return;
		}

		let scrollElement: HTMLElement | null = null;
		if (this.parentScrollSize === null) {
			scrollElement = await event.target.getScrollElement();
			if (scrollElement) {
				this.parentScrollSize = scrollElement.scrollHeight - scrollElement.clientHeight;
			}
		}

		const isParentScrollingEnabled = event.detail.scrollTop < (this.parentScrollSize ?? 0);
		if (this.isParentScrollingEnabled !== isParentScrollingEnabled) {
			if (!isParentScrollingEnabled) {
				if (scrollElement === null) {
					scrollElement = await event.target.getScrollElement();
				}
				if (scrollElement) {
					// If scrolling was to fast, an additional scroll event must be triggered, for example to finalize
					// the Ionic header animation. Disable scrolling also here to ignore the fired scroll event.
					this.isParentScrollingEnabled = false;
					scrollElement.scrollTop = (this.parentScrollSize ?? 0) - 1;
					setTimeout(() => {
						if (scrollElement && scrollElement.scrollTop === (this.parentScrollSize ?? 0) - 1) {
							scrollElement.scrollTop = this.parentScrollSize ?? 0;
						}
					}, 200);
				}
			}
			this.enableParentScrolling(isParentScrollingEnabled);
		}
	}

	public onScrolling(_event: Event): void {
		if (!this.scrollElement) {
			return;
		}

		const scrollPos = this.scrollElement.measureScrollOffset('top');
		if (this.scrollParent) {
			if (
				this.isScrollingEnabled &&
				scrollPos <= 0 &&
				this.lastScrollPos !== null &&
				scrollPos < this.lastScrollPos
			) {
				this.enableParentScrolling(true);
			}
			this.lastScrollPos = scrollPos;
		}

		this.isScrollToTopVisible = scrollPos > this.scrollElement.measureViewportSize('vertical');
	}

	public scrollToTop() {
		this.lastScrollPos = null;
		this.scrollElement?.scrollTo({ top: 0, behavior: 'smooth' });
	}

	public goToLetter(letter: string) {
		const index = this.alphabetLetterIndex.get(letter);
		if (index !== null && index !== undefined) {
			this.viewport?.scrollToIndex(index);
		}
	}

	public closeSlidingItems(): Promise<boolean> {
		return this.list?.closeSlidingItems() ?? Promise.resolve(true);
	}

	private initComponent(): void {
		if (this.itemsSubscription) {
			this.itemsSubscription.unsubscribe();
		}

		if (!this.items) {
			this.loadedItems = null;
			this.isAlphabetSideBarVisible = false;
			return;
		}
		this.itemsSubscription = (instanceOfObservable(this.items) ? this.items : of(this.items))
			.pipe(takeUntil(this.destruction))
			.subscribe(allData => {
				this.loadedItems = (allData as any[]) ?? [];

				this.isAlphabetSideBarVisible =
					this.showAlphabetSideBar && this.alphabetSideBarMinCount <= this.loadedItems.length;

				if (this.isAlphabetSideBarVisible) {
					this.initAlphapetSidebar().then(() => this.calculateContentSize());
				} else if (!this.showAlphabetSideBar) {
					this.initGroupHeaders();
					this.calculateContentSize();
				}
			});
	}

	private calculateContentSize(): void {
		if (!this.viewport || !this.loadedItems) {
			return;
		}

		const bufferItems = Math.floor(
			(this.maxBufferPx + this.minBufferPx + (this.scrollElement?.measureViewportSize('vertical') ?? 0)) /
				(this.itemHeight ?? 0)
		);
		const indexOfLastBuffer = this.loadedItems.length - bufferItems - 1;
		const headersOfLastBuffer = this.loadedItems.filter(
			(item, index) => index >= indexOfLastBuffer && item?.isFirstItemInGroup
		).length;

		// Set content delayed, because virtual scroll will recalculate it after loadedItems where changed
		delayedPromise(200).then(() => {
			this.viewport?.setTotalContentSize(
				(this.itemHeight ?? 0) * (this.loadedItems?.length ?? 0) + headersOfLastBuffer * this.groupHeaderHeight
			);
		});
	}

	private async initAlphapetSidebar(): Promise<void> {
		this.buildAphabeticalJumpList();
		this.alphabetSideBarTopOffset = null;

		// Setup swipe guesture for letter navigation
		await firstValueFrom(this.alphabetSidebarReady$.pipe(filter(isReady => !!isReady)));

		let lastLetterIndex: number | null = null;
		const sidebar = this.alphabetSidebarContent?.nativeElement;
		const swipeGesture = this.gestureController.create({
			el: sidebar,
			gestureName: 'swipe',
			direction: 'y',
			onStart: () => {
				if (this.alphabetSideBarTopOffset === null) {
					const firstLetter = document.getElementById('virtual_scroll_letter_0');
					this.alphabetSideBarTopOffset = +(firstLetter?.offsetTop ?? 0) + 50;
				}
			},
			onMove: ev => {
				const letterIndex = Math.floor((ev.currentY - (this.alphabetSideBarTopOffset ?? 0)) / 16);
				if (lastLetterIndex !== letterIndex) {
					lastLetterIndex = letterIndex;
					const letter = this.alphabet[letterIndex];
					this.goToLetter(letter);
				}

				if (ev.event.cancelable) {
					ev.event.preventDefault();
					ev.event.stopImmediatePropagation();
				}
			}
		});
		swipeGesture.enable(true);
	}

	private buildAphabeticalJumpList(): void {
		this.alphabet = [];
		this.alphabetLetterIndex = new Map<string, number>();

		if (!this.groupHeaderFunc) {
			return;
		}

		const noLetterItems = [];
		for (let index = 0; index < (this.loadedItems?.length ?? 0); index++) {
			const item = this.loadedItems ? this.loadedItems[index] : null;
			item.groupHeader = this.groupHeaderFunc(item) ?? '';
			const letter: string = SortHelper.firstLetterGrouping(item.groupHeader);
			item.isFirstItemInGroup = !this.alphabet.includes(letter);

			if (letter === '#') {
				// Push all non-letter items to the end, because they may be wrong sorted
				this.loadedItems?.splice(index--, 1);
				noLetterItems.push(item);
			}
			if (item.isFirstItemInGroup) {
				this.alphabetLetterIndex.set(letter, index);
				if (this.alphabet.includes('#')) {
					this.alphabet.splice(this.alphabet.length - 1, 0, letter);
				} else {
					this.alphabet.push(letter);
				}
			}
		}
		if (noLetterItems.length) {
			this.alphabetLetterIndex.set('#', this.loadedItems?.length ?? 0);
			noLetterItems.forEach(item => this.loadedItems?.push(item));
		}
	}

	private initGroupHeaders(): void {
		if (!this.groupHeaderFunc) {
			return;
		}

		let prevGroup: string;
		this.loadedItems?.map(obj => {
			obj.groupHeader = this.groupHeaderFunc ? this.groupHeaderFunc(obj) ?? '' : '';
			obj.isFirstItemInGroup = prevGroup === undefined || prevGroup !== obj.groupHeader;
			prevGroup = obj.groupHeader;
		});
	}

	private enableParentScrolling(enabled: boolean = true): void {
		this.isScrollingEnabled = !enabled;
		this.isParentScrollingEnabled = enabled;
		this.parentScrollingEnabled.emit(this.isParentScrollingEnabled);
	}
}
