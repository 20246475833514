import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { RlePortalLibConfig } from './rle-portal-lib-config.interface';
import { LIB_CONFIG } from './rle-portal-lib-config.token';

@NgModule({
	imports: [CommonModule]
})
export class RlePortalLibModule {
	static forRoot(config: RlePortalLibConfig): ModuleWithProviders<RlePortalLibModule> {
		return {
			ngModule: RlePortalLibModule,
			providers: [{ provide: LIB_CONFIG, useValue: config }]
		};
	}
}
