import { Injectable, NgZone } from '@angular/core';
import {
    Auth,
    getRedirectResult,
    OAuthProvider,
    onAuthStateChanged,
    signInWithCredential,
    signInWithRedirect,
    signOut,
    useDeviceLanguage,
    User as FirebaseUser,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { RlePortalLibConfig, RlePortalLibConfigService } from '@rle-portal/lib';
import { NotificationService } from '@rle-portal/lib/components';
import { sha256 } from 'js-sha256';
import { jwtDecode } from 'jwt-decode';
import Keycloak from 'keycloak-js';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable, of, ReplaySubject, Subject, Subscription, TimeoutError } from 'rxjs';
import { take, timeout } from 'rxjs/operators';

import { UserService } from './user/user.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public static PASSWORD_STRENGTH_REGEX = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"§$%&/()=?*+#<>@]).{8,}';
	public static PASSWORD_STRENGTH_SPECIAL_CHARS = '!"§$%&/()=?*+#<>@';

	private static REDIRECT_URL_STORAGE_KEY = 'login_redirect_url';
	private static KEYCLOAK_NONCE_STORAGE_KEY = 'kc-nonce';

	public loggedInUser: Observable<FirebaseUser | null>;
	public loggedInUserId: Observable<string | null>;
	public tokenRefreshed: Observable<boolean>;

	private config: RlePortalLibConfig;
	private loggedInUserSubscription?: Subscription;
	private loggedInUserSubject = new ReplaySubject<FirebaseUser | null>(1);
	private loggedInUserIdSubject = new ReplaySubject<string | null>(1);
	private currentLoggedInUser: FirebaseUser | null = null;
	private authStateChangeInProgress = false;
	private isLoginInProgress = false;
	private tokenRefreshedSubject = new Subject<boolean>();
	private isEmbeddedInNativeValue: boolean = false;

	constructor(
		config: RlePortalLibConfigService,
		private auth: Auth,
		private userService: UserService,
		private permissionsService: NgxPermissionsService,
		private router: Router,
		private platform: Platform,
		private zone: NgZone,
		private notificationService: NotificationService
	) {
		this.config = config.getConfig();
		this.loggedInUserIdSubject.next(null);
		this.loggedInUser = this.loggedInUserSubject.asObservable();
		this.loggedInUserId = this.loggedInUserIdSubject.asObservable();
		this.tokenRefreshed = this.tokenRefreshedSubject.asObservable();

		// Check if this app is embedded within a native iframe
		console.log('Hash:' + new URL(window.location.href).hash.substring(1));
		const params = new URLSearchParams(new URL(window.location.href).hash.substring(1));
		if (params.get('embeddedInNative')) {
			this.isEmbeddedInNativeValue = params.get('embeddedInNative') === 'true';
			//this.userDefaultsService.setIsEmbeddedInNative(this.isEmbeddedInNativeValue);
			const storeRequest = JSON.stringify({ action: 'storeData', clientId: 'rleportal-customers' });
			window.parent.postMessage(storeRequest, '*');
		}
	}

	public init(): Promise<boolean> {
		return (
			this.platform
				.ready()
				// .then(() => this.userDefaultsService.getIsEmbeddedInNative())
				// .then(isEmbedded => {
				// 	this.isEmbeddedInNativeValue = isEmbedded === true || this.isEmbeddedInNativeValue;
				// 	return Promise.resolve();
				// })
				.then(() => useDeviceLanguage(this.auth))
				.then(() => this.initAuthStateHandler())
				// Load current Firebase user to ensure that user is loaded before first login check
				.then(() => this.processLoginReturnResult())
				.catch(error => {
					if (error?.code === 'auth/user-cancelled') {
						return Promise.resolve(false);
					}

					this.processError(error, true);
					return signOut(this.auth).then(() => Promise.resolve(false));
				})
		);
	}

	public fetchIsEmbeddedInNative(): Promise<boolean> {
		const fetchRequest = JSON.stringify({ action: 'retrieveData', clientId: 'rleportal-customers' });
		window.parent.postMessage(fetchRequest, '*');
		return Promise.resolve(this.isEmbeddedInNative());
	}

	public isEmbeddedInNative(): boolean {
		return this.isEmbeddedInNativeValue;
	}

	public isLoggedIn(): boolean {
		return !!this.currentLoggedInUser;
	}

	public getCurrentLoggedInUser(): FirebaseUser | null {
		return this.currentLoggedInUser ?? null;
	}

	public async login(origin?: string): Promise<void> {
		if (this.config.clientLogEnabled) {
			console.log(`Show login with origin: ${origin}`);
		}
		this.setRedirectUrl(origin ?? null);

		// let redirectUri = Capacitor.isNativePlatform()
		// 	? environment.keycloak.redirectUriNative
		// 	: environment.keycloak.redirectUri;
		// if (origin) {
		// 	let pathIndex = redirectUri.indexOf('/', 8);
		// 	console.log('Redirect URI:', redirectUri, origin, pathIndex);
		// 	redirectUri =
		// 		(pathIndex === -1 ? redirectUri : redirectUri.substring(0, pathIndex)) +
		// 		'/' +
		// 		(origin[0] === '/' ? origin.substring(1) : origin);
		// }

		if (this.isEmbeddedInNative()) {
			return new Promise<void>((resolve, reject) => {
				window.addEventListener(
					'message',
					event => {
						const data = JSON.parse(event.data ?? '');
						if (data.action === 'tokenExchangeResult') {
							console.log('Received tokens:', data);

							// Login to Firebase
							const provider = new OAuthProvider('oidc.keycloak');
							const credential = provider.credential({
								idToken: data.idToken,
								accessToken: data.accessToken,
								rawNonce: data.rawNonce
							});
							signInWithCredential(this.auth, credential)
								.then(userCredential => this.processAuthToken(userCredential.user, true))
								.then(() => resolve())
								.catch(err => reject(err));
						}
					},
					{ capture: true, once: true }
				);

				const data = JSON.stringify({ action: 'tokenExchange', clientId: 'rleportal-customers' });
				window.parent.postMessage(data, '*');
			});
		} else if (Capacitor.isNativePlatform()) {
			const keycloak = await this.initKeycloak();
			if (!keycloak) {
				return this.processError('Could not init login system');
			}
			let url = keycloak.createLoginUrl({ scope: 'organisation' });

			// To use the token later for Firebase login, we need the raw nonce. So we have to create it manually and keep it before SHA-256 hashing
			const startIndex = url.indexOf('nonce=') + 6;
			let endIndex = url.indexOf('&', startIndex);
			endIndex = endIndex === -1 ? url.length - 1 : endIndex;
			let nonce = url.substring(startIndex, endIndex);
			// Now hash the nonce because Firebase will also hash the later provided rawNonce before comparing it with the one of the ID Token
			const nonceHashed = sha256(nonce);
			// Store the raw nonce for later usage after redirecting back from Keycloak
			localStorage.setItem(AuthService.KEYCLOAK_NONCE_STORAGE_KEY, nonce);
			// Use the new created nonce for the tokens
			url = url.substring(0, startIndex) + nonceHashed + url.substring(endIndex);

			console.log('Start Keycloak login', url);

			return Browser.open({ url });
			//return this.waitUntilTokenRefreshed();
		} else {
			const provider = new OAuthProvider('oidc.keycloak');
			provider.setCustomParameters({
				// Target specific email with login hint.
				//login_hint: 'user@example.com'
				//redirect_uri: redirectUri
			});
			provider.addScope('organisation');
			return signInWithRedirect(this.auth, provider).catch(error => this.processError(error));
		}
	}

	public async logout(): Promise<boolean> {
		try {
			// Firebase logout
			await signOut(this.auth);

			// Keycloak logout
			// if (this.keycloak) {
			// 	const url = this.keycloak.createLogoutUrl();

			// 	if (Capacitor.isNativePlatform()) {
			// 		await Browser.open({ url });
			// 		// In iOS explicitly close the browser after successful logout
			// 		if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
			// 			await Browser.close();
			// 		}
			// 	} else {
			// 		window.location.href = url;
			// 	}
			// }

			//window.location.reload(); //.href = '/login/logout';
			return true;
		} catch (err) {
			await this.processError(err);
			return false;
		}
	}

	private waitUntilTokenRefreshed(): Promise<void> {
		return new Promise((resolve, reject) =>
			this.tokenRefreshed.pipe(take(1), timeout(20000)).subscribe({
				next: () => resolve(),
				error: e => (e instanceof TimeoutError ? resolve() : reject())
			})
		);
	}

	private initAuthStateHandler(): Promise<boolean> {
		if (Capacitor.isNativePlatform()) {
			window.addEventListener(
				'message',
				event => {
					const data = JSON.parse(event.data);
					console.log('Exchange tokens for client:', data);
					if (data?.action === 'tokenExchange') {
						this.currentLoggedInUser?.getIdTokenResult(false).then(tokenResult => {
							console.log('Token result:', tokenResult);
							//const credentials = OAuthProvider.credentialFromResult(tokenResult);
							const accessToken = tokenResult.token; // credentials?.accessToken;
							this.callKeycloakTokenEndpoint('urn:ietf:params:oauth:grant-type:token-exchange', [
								{ key: 'subject_token', value: accessToken },
								{ key: 'audience', value: data.clientId },
								{ key: 'scope', value: 'organisation' }
							]).then(tokens => {
								const response = JSON.stringify({
									action: 'tokenExchangeResult',
									accessToken: tokens.access_token,
									idToken: tokens.id_token,
									refreshToken: tokens.refresh_token,
									rawNonce: ''
								});
								event.source?.postMessage(response, { targetOrigin: '*' });
							});
						});
					} else if (data?.action === 'storeData') {
						localStorage.setItem(data!.clientId + '###' + data!.key, data!.value);
						const response = JSON.stringify({
							action: 'storeDataResult'
						});
						event.source?.postMessage(response, { targetOrigin: '*' });
					} else if (data?.action === 'retrieveData') {
						let value = localStorage.getItem(data!.clientId + '###' + data!.key);
						const response = JSON.stringify({
							action: 'retrieveDataResult',
							value
						});
						event.source?.postMessage(response, { targetOrigin: '*' });
					}
				},
				{ capture: true }
			);
		}

		return new Promise<boolean>((resolve, reject) => {
			onAuthStateChanged(
				this.auth,
				firebaseUser => {
					this.zone.run(async () => {
						await this.notificationService.showSpinner();
						this.authStateChangeInProgress = true;
						if (this.config.clientLogEnabled) {
							console.log('authState changed: ', firebaseUser?.uid, firebaseUser);
						}
						if (this.loggedInUserSubscription) {
							this.loggedInUserSubscription.unsubscribe();
						}
						this.loggedInUserSubscription = this.processUser(firebaseUser).subscribe({
							next: firebaseUser => {
								this.zone.run(async () => {
									await this.notificationService.hideSpinner();
									this.setCurrentUser(firebaseUser);

									if (this.authStateChangeInProgress) {
										this.redirectAfterLogin();
									}
									this.authStateChangeInProgress = false;
									this.isLoginInProgress = false;
									resolve(true);
								});
							},
							error: error => {
								this.zone.run(async () => {
									// Ignore error if processing was canceled
									if (error) {
										this.processError(error, true);
									} else {
										await this.notificationService.hideSpinner();
									}
									this.authStateChangeInProgress = false;
									this.isLoginInProgress = false;
									resolve(false);
								});
							}
						});
					});
				},
				async error => {
					this.authStateChangeInProgress = false;
					this.isLoginInProgress = false;
					await this.notificationService.hideSpinner();
					reject(error);
				}
			);
		});
	}

	private processLoginReturnResult(): Promise<boolean> {
		// If embedded in iframe within native app, there is no redirect - it is done via message to parent frame
		if (this.isEmbeddedInNative()) {
			return Promise.resolve(false);
		}
		// Reqister handler to check result after redirect back from Identity Provider on native app
		else if (Capacitor.isNativePlatform()) {
			App.addListener('appUrlOpen', data => {
				this.processKeycloakCallback(data.url);
			});
			return Promise.resolve(true);
		}
		// Check result after redirect back from Identity Provider on web
		return getRedirectResult(this.auth).then(userCredential => Promise.resolve(!!userCredential));
	}

	private processUser(firebaseUser: FirebaseUser | null): Observable<FirebaseUser | null> {
		if (firebaseUser) {
			return this.auth.currentUser ? from(this.processAuthToken(firebaseUser)) : of(null);
		} else {
			this.permissionsService.flushPermissions();
			this.setRedirectUrl(null);
			return of(null);
		}
	}

	private processAuthToken(firebaseUser: FirebaseUser, forceRefresh = false): Promise<FirebaseUser> {
		if (!firebaseUser) {
			return Promise.resolve(firebaseUser);
		}

		// Force token refresh if native login, because beforeSignin handler function is not processed then
		// const forceRefresh = this.refreshTokenAfterLogin;
		// this.refreshTokenAfterLogin = false;

		return firebaseUser.getIdTokenResult(forceRefresh).then(tokenResult => {
			let permissionsList: string[] = (tokenResult?.claims?.['permissions'] as string[]) ?? [];
			this.permissionsService.loadPermissions(permissionsList);

			if (this.config.clientLogEnabled) {
				console.log('Force token refresh: ', forceRefresh);
				console.log('Token claims: ', tokenResult.claims);
				console.log('User permissions: ', permissionsList);
			}

			this.tokenRefreshedSubject.next(true);
			return Promise.resolve(firebaseUser);
		});
	}

	private setCurrentUser(firebaseUser: FirebaseUser | null): void {
		const oldUserId = this.currentLoggedInUser?.uid ?? null;

		this.currentLoggedInUser = firebaseUser;
		this.loggedInUserSubject.next(firebaseUser ?? null);
		if (oldUserId !== (firebaseUser?.uid ?? null)) {
			this.loggedInUserIdSubject.next(firebaseUser?.uid ?? null);
		}
	}

	private setRedirectUrl(url: string | null): void {
		if (url && /^\/?(login|logout|error)(\/.*)?$/i.test(url)) {
			return;
		}
		if (url) {
			localStorage.setItem(AuthService.REDIRECT_URL_STORAGE_KEY, url);
		} else {
			localStorage.removeItem(AuthService.REDIRECT_URL_STORAGE_KEY);
		}
	}

	private getRedirectUrl(): string | null {
		return localStorage.getItem(AuthService.REDIRECT_URL_STORAGE_KEY);
	}

	private redirectAfterLogin(): void {
		const redirectUrl = this.getRedirectUrl();
		if (redirectUrl) {
			console.log('Redirect after login to:' + redirectUrl);
			this.router.navigateByUrl((redirectUrl.startsWith('/') ? '' : '/') + redirectUrl, { replaceUrl: true });
			this.setRedirectUrl(null);
		} else if (this.authStateChangeInProgress && this.isLoginInProgress && Capacitor.isNativePlatform()) {
			console.log('Redirect after login to root');
			// Ensure a page reload after password based registration / login, so that the AuthGuard comes in place
			// for correct redirection.
			// Also on native devices after logout and relogin, no redirect is set, so leave login page and goto home.
			this.router.navigateByUrl('/', { replaceUrl: true });
		}
	}

	private async initKeycloak(): Promise<Keycloak | null> {
		const keycloak = new Keycloak({
			url: this.config.keycloak.url,
			realm: this.config.keycloak.realm,
			clientId: this.config.keycloak.clientId
		});

		return keycloak
			?.init({
				adapter: 'default',
				//onLoad: 'check-sso',
				redirectUri: this.config.keycloak.redirectUri,
				checkLoginIframe: false,
				silentCheckSsoFallback: false,
				enableLogging: this.config.clientLogEnabled
			})
			.then(() => Promise.resolve(keycloak))
			.catch(async err => {
				await this.processError(err);
				return null;
			});
	}

	private processKeycloakCallback(url: string): Promise<boolean> {
		const params = new URLSearchParams(new URL(url).hash.substring(1));
		const code = params.get('code');
		console.log('URL open with code:', code, url);
		if (code) {
			this.zone.run(async () => {
				// If code provided, the login flow is in process, so generate the token
				const stateKey = params.get('state') ?? '';
				const rawNonce = localStorage.getItem(AuthService.KEYCLOAK_NONCE_STORAGE_KEY) ?? '';
				localStorage.removeItem(AuthService.KEYCLOAK_NONCE_STORAGE_KEY);
				return this.exchangeKeycloakToken(stateKey, code)
					.then(async tokens => {
						// Login to Firebase
						const provider = new OAuthProvider('oidc.keycloak');
						const credential = provider.credential({
							idToken: tokens.id_token,
							accessToken: tokens.access_token,
							rawNonce
						});
						// In iOS explicitly close the browser after successful login
						if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
							await Browser.close();
						}
						if (this.config.clientLogEnabled) {
							console.log('Login to Firebase with Keycloak credential:', credential);
						}
						return credential;
					})
					.then(credential =>
						signInWithCredential(this.auth, credential).then(userCredential =>
							this.userService
								.setTokenClaims(userCredential?.user?.uid ?? '', credential?.accessToken ?? '')
								.then(() => this.processAuthToken(userCredential.user, true))
						)
					)
					.then(() => Promise.resolve(true))
					.catch(error => {
						this.processError(error);
						return Promise.resolve(false);
					});
			});
		}
		return Promise.resolve(false);
	}

	private exchangeKeycloakToken(
		stateKey: string,
		code: string
	): Promise<{ access_token: string; id_token: string; refresh_token: string }> {
		let state: { pkceCodeVerifier: string } = JSON.parse(localStorage.getItem('kc-callback-' + stateKey) ?? '');
		let codeVerifier = state?.pkceCodeVerifier ?? '';

		return this.callKeycloakTokenEndpoint('authorization_code', [
			{ key: 'code', value: code },
			{ key: 'code_verifier', value: codeVerifier }
		]).then(tokens => {
			const parsedToken = jwtDecode(tokens.access_token);
			const localTime = new Date().getTime() / 1000;
			const timeSkew = Math.floor(localTime) - parsedToken.iat!;
			const minValidity = 30;
			var expiresIn = parsedToken.exp! - Math.ceil(localTime) + timeSkew;
			if (expiresIn < minValidity) {
				return this.callKeycloakTokenEndpoint('refresh_token', [
					{ key: 'refresh_token', value: tokens.refresh_token }
				]);
			}
			return Promise.resolve(tokens);
		});
	}

	private callKeycloakTokenEndpoint(
		grantType: string,
		params: { key: string; value: string }[]
	): Promise<{ access_token: string; id_token: string; refresh_token: string }> {
		const tokenEndpoint = `${this.config.keycloak.url}/realms/${this.config.keycloak.realm}/protocol/openid-connect/token`;

		const body = new URLSearchParams();
		body.set('grant_type', grantType);
		body.set('client_id', this.config.keycloak.clientId);
		body.set('client_secret', this.config.keycloak.clientSecret);
		if (grantType === 'authorization_code') {
			body.set('redirect_uri', this.config.keycloak.redirectUri);
		}
		params.forEach(param => {
			body.set(param.key, param.value);
		});
		const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
		return CapacitorHttp.post({ url: tokenEndpoint, data: body.toString(), headers }).then(res => {
			if (res.status === 200) {
				const data = res.data;
				return { access_token: data.access_token, id_token: data.id_token, refresh_token: data.refresh_token };
			}
			return Promise.reject(res.status + ' - ' + res.data);
		});
	}

	private async processError(error: any, navigateToErrorPage = false): Promise<void> {
		if (this.config.clientLogEnabled) {
			console.error(error);
		}
		await this.notificationService.hideSpinner();
		return navigateToErrorPage
			? this.router.navigateByUrl('/error').then(() => Promise.resolve())
			: this.notificationService.showMessage(this.getErrorMessage(error));
	}

	private getErrorMessage(error: any): string {
		const code =
			error && Object.prototype.hasOwnProperty.call(error, 'code')
				? error.code
				: error && Object.prototype.hasOwnProperty.call(error, 'message')
					? error.message
					: error && typeof error === 'string'
						? error
						: '';
		switch (code) {
			case 'auth/user-not-found':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/wrong-password':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/email-already-in-use':
				return 'auth.messages.emailAlreadyInUse';
			case 'auth/credential-already-in-use':
				return 'auth.messages.accountAlreadyExistOrLinkedToAnotherAcc';
			case 'auth/account-exists-with-different-credential':
				return 'auth.messages.emailAlreadyInUse';
			case 'auth/app-deleted':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/app-not-authorized':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-email':
				return 'auth.messages.enteredEmailIsInvalid';
			case 'auth/argument-error':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/invalid-api-key':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-user-token':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-tenant-id':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/network-request-failed':
				return 'auth.messages.networkError';
			case 'auth/operation-not-allowed':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/operation-not-supported-in-this-environment':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/requires-recent-login':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/too-many-requests':
				return 'auth.messages.tooManyAttempts';
			case 'auth/unauthorized-domain':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/user-disabled':
				return 'auth.messages.accountDisabled';
			case 'auth/user-token-expired':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/web-storage-unsupported':
				return 'auth.messages.errorPleaseTryAgain';
			default:
				return Capacitor.isNativePlatform()
					? 'auth.messages.errorPleaseRestartApp'
					: 'auth.messages.errorPleaseReloadBrowser';
		}
	}
}
