import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorPage } from './error.page';
import { NotFoundPage } from './not-found/not-found.page';

const routes: Routes = [
	{
		path: '',
		component: ErrorPage
	},
	{
		path: 'notfound',
		component: NotFoundPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ErrorRoutingModule {}
