import { Inject, Injectable } from '@angular/core';

import { RlePortalLibConfig } from './rle-portal-lib-config.interface';
import { LIB_CONFIG } from './rle-portal-lib-config.token';

@Injectable({
	providedIn: 'root'
})
export class RlePortalLibConfigService {
	constructor(@Inject(LIB_CONFIG) private config: RlePortalLibConfig) {}

	getConfig(): RlePortalLibConfig {
		return this.config;
	}
}
