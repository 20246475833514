import { HttpClient } from '@angular/common/http';
import { AuthenticationProvider } from '@microsoft/microsoft-graph-client';
import { OAuthProvider, UserCredential } from 'firebase/auth';
import Keycloak from 'keycloak-js';
import { firstValueFrom } from 'rxjs';

export class ClientCredentialProvider implements AuthenticationProvider {
	constructor(
		private httpClient: HttpClient,
		private userCredential: UserCredential
	) {}
	public async getAccessToken(): Promise<string> {
		const credentials = OAuthProvider.credentialFromResult(this.userCredential);
		console.log(credentials);

		// Get Azure Token
		const azureToken = await this.getIdpTokenFromKeycloak(
			'master',
			'rle-realm',
			credentials?.accessToken ?? ''
		).then(masterToken => this.getIdpTokenFromKeycloak('rle', 'microsoft', masterToken));
		console.log('Azure token:', azureToken);
		// const cca = new ConfidentialClientApplication(clientConfig);

		// const response = await cca.acquireTokenByClientCredential({
		// 	scopes: ['https://graph.microsoft.com/.default']
		// });

		// if (response) {
		// 	return response.accessToken;
		if (azureToken) {
			return azureToken;
		} else {
			throw new Error('Cannot get Accesstoken from Microsoft Graph API');
		}
	}

	private getIdpTokenFromKeycloak(realm: string, idp: string, accessToken: string): Promise<string> {
		return firstValueFrom(
			this.httpClient.get<{ access_token: string; refresh_token: string; id_token: string }>(
				`https://identity.dev.portal.labs.rle.de/realms/${realm}/broker/${idp}/token`,
				{
					headers: { Authorization: `Bearer ${accessToken}` }
				}
			)
		)
			.then(async tokenResult => {
				console.log('Keycloak IdP token:', tokenResult, tokenResult.access_token);

				// Refresh the access token if expired
				if (realm === 'master') {
					const keycloak = new Keycloak({
						url: 'https://identity.dev.portal.labs.rle.de',
						realm: 'rle',
						clientId: 'master-keycloak'
					});
					try {
						await keycloak.init({
							token: tokenResult.access_token,
							refreshToken: tokenResult.refresh_token,
							idToken: tokenResult.id_token,
							timeSkew: 0,
							enableLogging: true
						});
						console.log('Keycloak authenticated after init:' + keycloak.authenticated);
						console.log('Keycloak token parsed:' + keycloak.tokenParsed);
						if (keycloak.isTokenExpired()) {
							console.log('Keycloak token expired');
						}
						await keycloak.updateToken().then(tokenRefreshed => {
							console.log('Keycloak token refreshed:' + tokenRefreshed);
							console.log('Keycloak token:', keycloak.token);
						});
						if (!keycloak.token) {
							return Promise.reject('Error refreshing Keycloak token');
						}
						return Promise.resolve(keycloak.token ?? null);
					} catch (error) {
						console.log('Error refreshing Keycloak token:', error);
						return Promise.reject(error);
					}
				}

				return Promise.resolve(tokenResult.access_token);
			})
			.catch(error => {
				console.log('ERROR while retrieving Azure Token:', error);
				return Promise.reject(error);
			});
	}
}
