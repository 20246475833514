import { Component, OnDestroy } from '@angular/core';
import { App, AppState } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { I18nService, ThemeService } from '@rle-portal/lib';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
	public swipeGesturesEnabled = true;
	private destruction = new Subject<void>();

	constructor(
		private platform: Platform,
		themeService: ThemeService,
		private i18nService: I18nService
	) {
		if (environment.clientLogEnabled) {
			console.log(
				`isNative: ${Capacitor.isNativePlatform() ? 'yes' : 'no'} (${Capacitor.getPlatform()}) - Platform: ${
					this.platform.is('ios') ? 'ios' : this.platform.is('android') ? 'android' : 'desktop?'
				}`
			);
		}
		this.swipeGesturesEnabled = Capacitor.isNativePlatform();

		// Switch to Dark Mode, if enabled
		themeService.themeMode
			.pipe(takeUntil(this.destruction))
			.subscribe(mode => themeService.switchThemeMode(mode === 'dark'));

		this.initializeApp();
	}

	public ngOnDestroy(): void {
		this.destruction.next();
		this.destruction.complete();
	}

	private initializeApp(): void {
		this.platform.ready().then(() => {
			SplashScreen.hide();
			if (Capacitor.isNativePlatform()) {
				StatusBar.setStyle({ style: Style.Dark });
				if (this.platform.is('android')) {
					StatusBar.setOverlaysWebView({ overlay: true });

					// Fix Capacitor bug when web view overlay is enabled. On Android the keyboard then overlaps the
					// bottom inputs.
					this.handleKeyboardToggle();
				}

				// detect if the application is foreground or background
				this.runAppStateChangeListener();
			}

			// Safe area is not set correctly by Ionic / Capacitor by default, especially for devices with a notch
			//if (window.AndroidNotch) {
			//	this.setAndroidSafeArea();
			//}

			// Init translations
			this.i18nService.initTranslate().then(() => {});
		});
	}

	private handleKeyboardToggle(): void {
		Keyboard.addListener('keyboardDidShow', keyboardInfo => {
			const style = document.documentElement.querySelector('ion-app')?.style;
			style?.setProperty('margin-bottom', `${keyboardInfo.keyboardHeight}px`);
		});
		Keyboard.addListener('keyboardWillHide', () => {
			const style = document.documentElement.querySelector('ion-app')?.style;
			style?.setProperty('margin-bottom', '0px');
		});
	}

	// private setAndroidSafeArea(): void {
	// 	const minStatusBarHeight = 25;
	// 	const minNavigationBarHeight = 50;
	// 	const style = document.documentElement.style;
	// 	window.AndroidNotch.getInsetTop(px => {
	// 		style.setProperty(
	// 			'--ion-safe-area-top',
	// 			`${Math.max(minStatusBarHeight, +px)}px`
	// 		);
	// 	});
	// 	window.AndroidNotch.getInsetBottom(px =>
	// 		style.setProperty(
	// 			'--ion-safe-area-bottom',
	// 			`${Math.max(minNavigationBarHeight, +px)}px`
	// 		)
	// 	);
	// 	window.AndroidNotch.getInsetLeft(px =>
	// 		style.setProperty('--ion-safe-area-left', `${px as string}px`)
	// 	);
	// 	window.AndroidNotch.getInsetRight(px =>
	// 		style.setProperty('--ion-safe-area-right', `${px as string}px`)
	// 	);
	// }

	private runAppStateChangeListener(): void {
		App.addListener('appStateChange', (state: AppState) => {
			if (state.isActive) {
				// App has become active
				this.i18nService.setLanguageByDevice();
			} else {
				// App has become inactive
			}
		});
	}
}
